import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})
export default new Vuex.Store({
  state: {
    accessToken:"",
    rollCallTime:parseInt(new Date().getTime()/1000)
  },
  getters: {
    //get方法
    getStatistics: state => state.rollCallTime
  },
  mutations: {
    rollCallTime(state,rollCallTime){
      state.rollCallTime = rollCallTime;
    },
    SETACCESS_TOKEN(state,step) {
      console.log(step)      // 这里将state传进来了
      state.accessToken = step
  }
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
