import Vue from 'vue'
import VueRouter from 'vue-router'
//保育
import Cookies from 'js-cookie'
Vue.use(VueRouter)
import { encrypt, decrypt } from '@/utils/jsencrypt';
const routes = [{
    path: '/',
    redirect: '/home', // 重定向:重新指向其它path,会改变网址

  },
  {
    path: '/loginhome',
    name: 'loginhome',
    component: () => import('@/views/loginhome.vue'),
    meta: {
      title: "登入首页",
      isRequired: false,
      tabar: false,
      buttonbar: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: "登入",
      isRequired: false,
      tabar: false
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/agreement.vue'),
    meta: {
      isRequired: false,
      tabar:true
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/privacy.vue'),
    meta: {
      isRequired: false,
      tabar:true
    },
  },

  {
    path: '/loginEdit',
    name: 'loginEdit',

    component: () => import('@/views/loginEdit.vue'),
    meta: {
      title: "修改密码",
      isRequired: false,
      tabar: false
    }
  },
  {
    path: '/home',
    name: 'home',

    component: () => import('@/views/home/home.vue'),
    meta: {
      title: "员工端首页",
      isRequired: true,
      tabar: false
    }
  },
  {
    path: '/signIn',
    name: 'signIn',
    component: () => import("@/views/signIn/signIn.vue"),
    meta: {
      title: "签到",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/weeklyPlan',
    name: 'weeklyPlan',
    component: () => import("@/views/weeklyPlan/weeklyPlan.vue"),
    meta: {
      title: "周计划",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/addWeeklyPlan',
    name: 'addWeeklyPlan',
    component: () => import("@/views/weeklyPlan/addWeeklyPlan.vue"),
    meta: {
      title: "周计划",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/checkIn',
    name: 'checkIn',
    component: () => import("@/views/checkIn/checkIn.vue"),
    meta: {
      title: "晨午晚检",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/drug',
    name: 'drug',
    component: () => import("@/views/drug/drug.vue"),
    meta: {
      title: "药品记录",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/dining',
    name: 'Dining',
    component: () => import("@/views/Dining/dining.vue"),
    meta: {
      title: "用餐记录",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/milk',
    name: 'milk',
    component: () => import("@/views/milk/milk.vue"),
    meta: {
      title: "奶粉记录",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import("@/views/menu/menu.vue"),
    meta: {
      title: "今日菜单",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/interestClass',
    name: 'interestClass',
    component: () => import("@/views/interestClass/interestClass.vue"),
    meta: {
      title: "兴趣班",
      isRequired: true,
      tabar: true
    }
  }, {
    path: '/interestClassEdit',
    name: 'interestClassEdit',
    component: () => import("@/views/interestClassEdit/interestClassEdit.vue"),
    meta: {
      title: "兴趣班详情",
      isRequired: true,
      tabar: true
    }
  }, {
    path: '/callName',
    name: 'callName',
    component: () => import("@/views/callName/callName.vue"),
    meta: {
      title: "兴趣班详情",
      isRequired: true,
      tabar: true
    }
  }, {
    path: '/record',
    name: 'record',
    component: () => import("@/views/record/record.vue"),
    meta: {
      title: "考勤",
      isRequired: true,
      tabar: true
    }
  }, {
    path: '/followRemindEdit',
    name: 'followRemindEdit',
    component: () => import("@/views/followRemindEdit/followRemindEdit.vue"),
    meta: {
      title: "跟踪提醒",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/employeeTraining',
    name: 'employeeTraining',
    component: () => import("@/views/employeeTraining"),
    meta: {
      title: "宝宝出勤率",
      isRequired: true,
      tabar: true
    }
  },

  {
    path: '/toy',
    name: 'toy',
    component: () => import("@/views/toy/toy.vue"),
    meta: {
      title: "玩具管理",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/empLeave',
    name: 'empLeave',
    component: () => import("@/views/empLeave/empLeave.vue"),
    meta: {
      title: "员工请假",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/observation',
    name: 'observation',
    component: () => import("@/views/observation/observation.vue"),
    meta: {
      title: "观察记录",
      isRequired: true,
      tabar: true
    }
  }, {
    path: '/librarys',
    name: 'librarys',
    component: () => import("@/views/librarys"),
    meta: {
      title: "培训库",
      isRequired: true,
      tabar: true,
      keepAlive: true
    }
  }, {
    path: '/preview',
    name: 'preview',
    component: () => import("@/views/preview"),
    meta: {
      title: "预案库",
      isRequired: true,
      tabar: true,
      keepAlive: true
    }
  },
  {
    path: '/life',
    name: 'life',
    component: () => import("@/views/life/life.vue"),
    meta: {
      title: "集体生活",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/study',
    name: 'study',
    component: () => import("@/views/study/study.vue"),
    meta: {
      title: "学习主题",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/learning1',
    name: 'learning1',
    component: () => import("@/views/learning/learning1.vue"),
    meta: {
      title: "生活记录",
      isRequired: true,
      tabar: true,
      // operating:'编辑'
    }
  },
  {
    path: '/babyLive',
    name: 'babyLive',
    component: () => import("@/views/babyLive/babyLive.vue"),
    meta: {
      title: "宝宝在线",
      isRequired: true,
      tabar: true,
      // operating:'编辑'
    }
  },
  {
    path: '/learning',
    name: 'learning',
    component: () => import("@/views/learning/learning.vue"),
    meta: {
      title: "生活记录",
      isRequired: true,
      tabar: true,
      // operating:'编辑'
    }
  },
  {
    path: '/disinfect',
    name: 'disinfect',
    component: () => import("@/views/disinfect/disinfect.vue"),
    meta: {
      title: "消毒记录",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/evaluation',
    name: 'evaluation',
    component: () => import("@/views/evaluation/evaluation.vue"),
    meta: {
      title: "测评报告",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/filemanage',
    name: 'filemanage',
    component: () => import("@/views/filemanage/filemanage.vue"),
    meta: {
      title: "照片视频",
      isRequired: true,
      tabar: true,
      keepAlive: true,
    }
  },
  {
    path: '/observeRecord',
    name: 'observeRecord',
    component: () => import("@/views/observeRecord/observeRecord.vue"),
    meta: {
      title: "添加观察记录",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/activeDetail',
    name: 'activeDetail',
    component: () => import("@/views/activeDetail/activeDetail.vue"),
    meta: {
      title: "活动通知",
      isRequired: true,
      tabar: true,
      operating:'一键已读'
    }
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/activeDetail/activity/activity.vue'),
    meta: {
      title:'活动通知',
      isRequired: true,
      tabar: true,
      buttonbar: true
    },
  },
  {
    path: '/activityDetailPage',
    name: 'activityDetailPage',
    component: () => import("@/views/activityEdit"),
    meta: {
      title: "活动详情",
      isRequired: true,
      tabar: true
    }

  }, {
    path: '/attendanceEdit',
    name: 'attendanceEdit',
    component: () => import("@/views/attendanceEdit"),
    meta: {
      title: "请假详情",
      isRequired: true,
      tabar: true
    }

  },
  {
    path: '/singabsent',
    name: 'singabsent',
    component: () => import("@/views/singabsent"),
    meta: {
      title: "宝宝缺勤",
      isRequired: true,
      tabar: true
    }


  },
  {
    path: '/absentList',
    name: 'absentList',
    component: () => import("@/views/absentList/absentList.vue"),
    meta: {
      title: "缺勤宝宝",
      isRequired: true,
      tabar: true
    }
  }, {
    path: '/attendance',
    name: 'attendance',
    component: () => import("@/views/attendance/attendance.vue"),
    meta: {
      title: "请假宝宝",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/codeEmp',
    name: 'codeEmp',
    component: () => import("@/views/codeEmp/codeEmp.vue"),
    meta: {
      title: "家长委托",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/entrust',
    name: 'entrust',
    component: () => import("@/views/entrust/entrust.vue"),
    meta: {
      title: "委托详情",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/entrustEdit',
    name: 'entrustEdit',
    component: () => import("@/views/entrust/entrustEdit.vue"),
    meta: {
      title: "添加委托记录",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/CheckInabnomal',
    name: 'CheckInabnomal',
    component: () => import("@/views/checkInabnomal/checkInabnomal.vue"),
    meta: {
      title: "晨检异常",
      isRequired: true,
      tabar: true
    }
  }, {
    path: '/parkRemind',
    name: 'parkRemind',
    component: () => import("@/views/parkRemind/parkRemind.vue"),
    meta: {
      title: "入园提醒",
      isRequired: true,
      tabar: true
    }
  }, {
    path: '/attention',
    name: 'attention',
    component: () => import("@/views/attention/attention.vue"),
    meta: {
      title: "重点关注",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/renewRemind',
    name: 'renewRemind',
    component: () => import("@/views/renewRemind/renewRemind.vue"),
    meta: {
      title: "续约提醒",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/followRemind',
    name: 'followRemind',
    component: () => import("@/views/followRemind/followRemind.vue"),
    meta: {
      title: "跟踪提醒",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/addlist',
    name: 'addlist',
    component: () => import("@/views/addList/addlist.vue"),
    meta: {
      title: "添加",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/checkInLock',
    name: 'checkInLock',
    component: () => import("@/views/checkInLock/checkInLock.vue"),
    meta: {
      title: "晨午晚检",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/signInAlready',
    name: 'signInAlready',
    component: () => import("@/views/signInAlready/signInAlready.vue"),
    meta: {
      title: "签到",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/drugDetail',
    name: 'drugDetail',
    component: () => import("@/views/drugDetail/drugDetail.vue"),
    meta: {
      title: "用药委托单",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/drugTicketAdd',
    name: 'drugTicketAdd',
    component: () => import("@/views/drugTicketAdd/drugTicketAdd.vue"),
    meta: {
      title: "添加委托单",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/diningEdit',
    name: 'diningEdit',
    component: () => import("@/views/diningEdit/diningEdit.vue"),
    meta: {
      title: "用餐",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/observationEdit',
    name: 'observationEdit',
    component: () => import("@/views/observationEdit/observationEdit.vue"),
    meta: {
      title: "观察",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/lifeEdit',
    name: 'lifeEdit',
    component: () => import("@/views/lifeEdit/lifeEdit.vue"),
    meta: {
      title: "生活新增",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/studyEdit',
    name: 'studyEdit',
    component: () => import("@/views/studyEdit/studyEdit.vue"),
    meta: {
      title: "学习新增",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/learningEdit',
    name: 'learningEdit',
    component: () => import("@/views/learningEdit/learningEdit.vue"),
    meta: {
      title: "生活记录",
      isRequired: true,
      tabar: true,
      // operating:'完成'
    }
  },
  {
    path: '/milkEdit',
    name: 'milkEdit',
    component: () => import("@/views/milkEdit/milkEdit.vue"),
    meta: {
      title: "委托奶粉",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/milkTicketAdd',
    name: 'milkTicketAdd',
    component: () => import("@/views/milkTicketAdd/milkTicketAdd.vue"),
    meta: {
      title: "添加委托奶粉",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/milkadd',
    name: 'milkAdd',
    component: () => import("@/views/milkAdd/milkadd.vue"),
    meta: {
      title: "奶粉新增",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/addDisinfect',
    name: 'addDisinfect',
    component: () => import("@/views/addDisinfect/addDisinfect.vue"),
    meta: {
      title: "消毒新增",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    component: () => import("@/views/evaluate/evaluate.vue"),
    meta: {
      title: "测评",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/ToSingin',
    name: 'ToSingin',
    component: () => import("@/views/ToSingin/ToSingin.vue"),
    meta: {
      title: "签到",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/upfilesView',
    name: 'upfilesView',
    component: () => import("@/views/upfiles/upfiles.vue"),
    meta: {
      title: "上传",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/babayAttendance',
    name: 'babayAttendance',
    component: () => import("@/views/Babyattendance/babayAttendance.vue"),
    meta: {
      title: "员工出勤率",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: () => import("@/views/subscribe/subscribe.vue"),
    meta: {
      title: "预约到访率",
      isRequired: true,
      tabar: true
    }
  }, {
    path: '/noto',
    name: 'noto',
    component: () => import("@/views/noto"),
    meta: {
      title: "预约试托率",
      isRequired: true,
      tabar: true
    }
  }, {
    path: '/entiretys',
    name: 'entiretys',
    component: () => import("@/views/entiretys"),
    meta: {
      title: "整体开发率",
      isRequired: true,
      tabar: true
    }
  }, {
    path: '/childCareBanner',
    name: 'childCareBanner',
    component: () => import("@/views/childCareBanner"),
    meta: {
      title: "保育流程",
      isRequired: true,
      tabar: true
    }
  },


  {
    path: '/group',
    name: 'group',
    component: () => import("@/views/group/group.vue"),
    meta: {
      title: "群体画像",
      isRequired: true,
      tabar: true
    }
  },
  //保育
  {
    path: '/childCare',
    name: 'childCareView',
    component: () => import("@/views/childCare/childCare.vue"),
    meta: {
      title: "保育流程",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/brewMethods',
    name: 'brewMethods',
    component: () => import("@/views/childCareItem/brewMethods/brewMethods.vue"),
    meta: {
      title: "冲泡牛奶保育流程",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/classroom',
    name: 'classroom',
    component: () => import("@/views/childCareItem/classroom/classroom.vue"),
    meta: {
      title: "课堂",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/dietary',
    name: 'dietary',
    component: () => import("@/views/childCareItem/dietary/dietary.vue"),
    meta: {
      title: "辅食保育流程",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/everyday',
    name: 'everyday',
    component: () => import("@/views/childCareItem/everyday/everyday.vue"),
    meta: {
      title: "日常",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/health',
    name: 'health',
    component: () => import("@/views/childCareItem/health/health.vue"),
    meta: {
      title: "医保保育流程",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/mouthClean',
    name: 'mouthClean',
    component: () => import("@/views/childCareItem/mouthClean/mouthClean.vue"),
    meta: {
      title: "保育流程",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/physique',
    name: 'physique',
    component: () => import("@/views/childCareItem/physique/physique.vue"),
    meta: {
      title: "体格",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/takeBath',
    name: 'takeBath',
    component: () => import("@/views/childCareItem/takeBath/takeBath.vue"),
    meta: {
      title: "洗澡保育流程",
      isRequired: true,
      tabar: true
    }
  }, {
    path: '/menuconfrim',
    name: 'menuconfrim',
    component: () => import("@/views/menuconfrim"),
    meta: {
      title: "确定今日菜单",
      isRequired: true,
      tabar: true
    }
  },



  {
    path: '/toilet',
    name: 'toilet',
    component: () => import("@/views/childCareItem/toilet/toilet.vue"),
    meta: {
      title: "入厕保育流程",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/empLeaveAdd',
    name: 'empLeaveAdd',
    component: () => import("@/views/empLeaveAdd"),
    meta: {
      title: "员工请假",
      isRequired: true,
      tabar: true
    }

  }, {
    path: '/personEdit',
    name: 'personEdit',
    component: () => import("@/views/personEdit"),
    meta: {
      title: "员工请假详情",
      isRequired: true,
      tabar: true
    }

  },
  {
    path: '/toothBrushing',
    name: 'toothBrushing',
    component: () => import("@/views/childCareItem/toothBrushing/toothBrushing.vue"),
    meta: {
      title: "刷牙保育流程",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/wash',
    name: 'wash',
    component: () => import("@/views/childCareItem/wash/wash.vue"),
    meta: {
      title: "洗手保育流程",
      isRequired: true,
      tabar: true
    }
  },
  {
    path: '/offlist',
    name: 'offlist',
    component: () => import("@/views/offlist"),
    meta: {
      title: "请假",
      isRequired: true,
      tabar: true
    }

  }, {
    path: '/Measurementgrowth',
    name: 'Measurementgrowth',
    component: () => import("@/views/Measurementgrowth"),
    meta: {
      title: "成长测评",
      isRequired: false,
      tabar: true
    }

  },  {
    path: `/growth`,
    name: 'growth',
    component: () => import("@/views/growth"),
    meta: {
      title: "进行测评",
      isRequired: false,
      tabar: true
    }

  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import("@/views/reports/index.vue"),
    meta: {
      title: "成长测评",
      isRequired: false,
      tabar: true
    }

  }, {
    path: `/reportschar`,
    name: 'reportschar',
    component: () => import("@/views/reports/reportschar.vue"),
    meta: {
      title: "成长测评",
      isRequired: false,
      tabar: true
    }

  },{
    path: '/activitys',
    name: 'activity',
    component: () => import("@/views/activity/index.vue"),
    meta: {
      title: "活动库",
      isRequired: true,
      tabar: true
    }

  },{
    path: '/activityedit',
    name: 'activityedit',
    component: () => import("@/views/activity/activeedit.vue"),
    meta: {
      title: "活动库",
      isRequired: true,
      tabar: true
    }

  },{
    path: '/move',
    name: 'move',
    component: () => import("@/views/move"),
    meta: {
      title: "moreFeatures",
      isRequired: true,
      tabar: true
    }

  },{
    path: '/moreFeatures',
    name: 'moreFeatures',
    component: () => import("@/views/moreFeatures"),
    meta: {
      title: "更多功能",
      isRequired: true,
      tabar: true
    }
    
  },{
    path: '/kpl',
    name: 'kpl',
    component: () => import("@/views/KPI"),
    meta: {
      title: "KPI数据看板",
      isRequired: true,
      tabar: true
    }

  },{
    path: '/ipadgroup',
    name: 'ipadgroup',
    component: () => import("@/views/ipadgroup"),
    meta: {
      title: "群体画像",
      isRequired: true,
      tabar: true
    }

  },{
    path: '/Planweek',
    name: 'Planweek',
    component: () => import("@/views/Planweek/index.vue"),
    meta: {
      title: "课程表",
      isRequired: true,
      tabar: true,
      buttonbar: true
    }
  },{
    path: '/addPlanweek',
    name: 'addPlanweek',
    component: () => import("@/views/Planweek/addPlanWeek.vue"),
    meta: {
      title: "课程表",
      isRequired: true,
      tabar: true,
      buttonbar: true
    }
  },{
    path: '/editWeekProgram',
    name: 'editWeekProgram',
    component: () => import("@/views/editWeekProgram/index.vue"),
    meta: {
      title: "编辑课程表",
      isRequired: true,
      tabar: true,
      buttonbar: true
    }
  },{
    path: '/addAWeeklyPlan',
    name: 'addAWeeklyPlan',
    component: () => import("@/views/addAWeeklyPlan/index.vue"),
    meta: {
      title: "添加课程表",
      isRequired: true,
      tabar: true,
      buttonbar: true
    }
  },
  {
    path: '/helpcenter',
    name: 'helpcenter',
    component: () => import("@/views/helpcenter/index.vue"),
    meta: {
      title: "帮助中心",
      isRequired: true,
      tabar: false,
      buttonbar: true
    }
    
  }, {
    path: '/helpcenterItem',
    name: 'helpcenterItem',
    component: () => import("@/views/helpcenter/helpcenterItem/helpcenterItem.vue"),
    meta: {
      title: "帮助中心",
      isRequired: true,
      tabar: true,
      buttonbar: true
    }
    
  }, {
    path: '/search',
    name: 'search',
    component: () => import("@/views/helpcenter/helpcenterItem/search.vue"),
    meta: {
      title: "快捷搜索",
      isRequired: true,
      tabar: true,
      buttonbar: true
    }
    
  }, {
    path: '/feedback',
    name: 'feedback',
    component: () => import("@/views/helpcenter/helpcenterItem/feedback.vue"),
    meta: {
      title: "意见反馈",
      isRequired: true,
      tabar: true,
      buttonbar: true
    }
    
  }, {
    path: '/settings',
    name: 'settings',
    component: () => import("@/views/helpcenter/helpcenterItem/settings.vue"),
    meta: {
      title: "账号设置",
      isRequired: true,
      tabar: true,
      buttonbar: true
    }
    
  },
  {
    path: '/check',
    name: 'check',
    component: () => import("@/views/check/index.vue"),
    meta: {
      title: "审核",
      isRequired: true,
      tabar: true,
      buttonbar: true
    }
  },
  {
    path: '/contractsituation',
    name: 'contractsituation',
    component: () => import("@/views/contractsituation/index.vue"),
    meta: {
      title: "签约情况",
      isRequired: true,
      tabar: false,
      buttonbar: true
    }
  }, {
    path: '/contraedit',
    name: 'contraedit',
    component: () => import("@/views/contractsituation/contraedit.vue"),
    meta: {
      title: "签约情况",
      isRequired: true,
      tabar: false,
      buttonbar: true
    }
  }

]



const router = new VueRouter({
  // mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({
    y: 0
  }),
  routes: routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.isRequired) { // 如果该路由需要验证
    if (Cookies.get("accessToken") && Cookies.get("phone")) { // token存在即进入该路由
      next()
    } else { // 不存在则跳转至登录页面
      next("/login")
    }
  } else { // 不需要验证直接跳转
    next()
  }
})
export default router
