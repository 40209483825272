<template>
  <div id="app">
	  <van-sticky :offset-top="0" v-show="$route.meta.tabar == true" z-index="9999">
		  <div class="tops">
			  <van-nav-bar :title="$route.meta.title" left-text="返回" left-arrow @click-left="onClickLeft"
			               @click-right="onClickRight">
				  <van-icon name="revoke" />
				  <template #right>
					  <!--<div  @click="add($route.meta.title)" v-preventDbClick>-->
						<!--  <span style="color: #ffffff">{{$route.meta.operating}}</span>-->
					  <!--</div>-->
					  <!--<van-icon name="ellipsis" size="18" />-->
				  </template>
			  </van-nav-bar>
		  </div>
		  <div class="ipadtops">
        <span>
          {{$route.meta.title}}
        </span>
			  <img src="@/static/ipadimg/esc.png" alt="" @click="onClickLeft">
		  </div>
	  </van-sticky>
	  <router-view ref="router" v-wechat-title="$route.meta.title" v-if="isRouterAlive" />
  </div>
</template>
<script>
// import FastClick from 'fastclick'
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },

  mounted() { },
  methods: {
	  add(val){
		  this.$bus.$emit("titleEvent",val);
	  },
    onClickLeft() {
      window.history.go(-1);
    },
    onClickRight() { },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style>
.bors {
  background: #fff;
  /* z-index: 999; */
  text-align: center;
  line-height: 50px;
  /* margin-top: 30px; */
  height: 10%;
  font-size: 14px;
  height: 50px;
}
.ipadtops{
  display: none;
}
.tops{
  display: block;
}
.imglist {
  width: 90px;
  height: 90px;
}


.imglist img {
  width: 100%;
  height: 100%;
}

.imglist video {
  width: 100%;
  height: 100%;
}

input,
select,
button {
  -webkit-appearance: none;
  appearance: none;
}

.test /deep/ .el-calendar-table .el-calendar-day {
  width: 60px;
  height: 40px;
}

#app {
  width: 100%;
	height: 100%;
  /*height: calc(100% - 1.22667rem);*/
  font-family: "SimHei";
  user-select: none;
}
.van-sticky--fixed{
/* background: rgb(111, 169, 255) !important;*/
 background: url('./static/img/top-bg5.png') top center no-repeat;
 background-size: 100% auto;
}
.van-nav-bar{
  background:none !important;
}
.van-nav-bar van-hairline--bottom {
  background: red;
}

.van-nav-bar__content {
  /* background: linear-gradient(#B3B2FF,#8280FF); */
  top: 0;
	height: 1.22667rem;
	/* border-radius: 0 0 15px 15px; */
}
 .van-hairline--bottom::after {
	border: none !important;
	/*border-bottom-width: 0.02667rem;*/
}

.van-nav-bar__title {
  color: #333 !important;
}

.van-nav-bar .van-icon {
  color: #333 !important;
}

.van-nav-bar__text {
  color: #333 !important;
}

@media screen and (min-width: 900px) {
 .ipadtops{
  display: block;
}
.tops{
  display: none;
}
.ipadtops{
  width: 100%;
  height: 30px;
  background: #FFC848;
   border-radius: 0px 0px 12px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

}
.ipadtops span{
  font-size: 7px;
  color: #fff;
}
.ipadtops img{
  width: 8%;
  position: absolute;
  left: 10px;
}

}
</style>
