import Vue from 'vue'

const preventDbClick = Vue.directive('preventDbClick', {
    inserted: function (el, binding) {
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true
                setTimeout(() => {
                    el.disabled = false
                }, 1000)
            }
        })
    }
});

export {
    preventDbClick
}
